/* Styles globaux */
.body-container {
    width: 100vw;
}

/* Information sur l'agence */
.info-agence {
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

/* En-tête */
.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin-left: 15vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
    border-radius: 8px;
}

/* Conteneur des cours des devises */
.cours-devises {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 4vh;
    border-radius: 8px;
    background-color: #ea5a27;
}

/* Date */
.date-container {
    display: flex;
    justify-content: center;
    margin-right: 5vw;
}

/* Données des devises */
.donnees-devises {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
}

.print-table {
    width: 100%;
    border-collapse: collapse;
}

.print-table th,
.print-table td {
    border: 1px solid black;
    padding: 8px;
}

/* Footer */
.footer {
    padding-top: 1vh;
}

.footer .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3vh;
}

/* Styles spécifiques pour l'impression */
@media print {
    .print-table {
        page-break-inside: auto;
    }

    .footer {
        position: fixed;
        bottom: 0;
        border-top: 2px solid #73AD21;
        align-items: center;
        width: 100vw;
        justify-content: center;
    }

    .print-table th,
    .print-table td {
        page-break-inside: avoid;
    }

    .footer {
        page: footer;
    }
}
