/* Styles for AppBar and Tabs */
.gestion-devise-container{
    margin-top: 7vh;
}
.gestion-devise-container .MuiAppBar-root {
    background-color: #ffffff;
}

.gestion-devise-container .MuiTabs-root {
    background-color: #ffffff;
}

.gestion-devise-container .MuiTab-root {
    font-size: 0.8rem;
    padding: 5px 35px 5px 20px !important;
}

.gestion-devise-container .MuiTab-root:hover {
    color: #868dfb !important;
}

.gestion-devise-container .MuiTab-root.Mui-selected {
    color: #6870fa !important;
}

/* Additional styling for content area */
.gestion-devise-content {
    margin: 1vh 0 0 0;
    height: 75vh;
}

/* Styling for specific elements within content area */
.gestion-devise-content .MuiDataGrid-root {
    border: none;
}

.gestion-devise-content .MuiDataGrid-cell {
    border-bottom: none;
}

.gestion-devise-content .name-column--cell {
    color: #a7ff83; /* Assuming 'colors.greenAccent[300]' translates to this */
}

.gestion-devise-content .MuiDataGrid-columnHeaders {
    background-color: #0052cc; /* Assuming 'colors.blueAccent[700]' translates to this */
    border-bottom: none;
}

.gestion-devise-content .MuiDataGrid-virtualScroller {
    background-color: #005cbf; /* Assuming 'colors.primary[400]' translates to this */
}

.gestion-devise-content .MuiDataGrid-footerContainer {
    border-top: none;
    background-color: #0052cc; /* Assuming 'colors.blueAccent[700]' translates to this */
}

.gestion-devise-content .MuiCheckbox-root {
    color: #7cc57f !important; /* Assuming 'colors.greenAccent[200]' translates to this */
}

/* Additional styles for Typography */
.gestion-devise-container .MuiTypography-root {
    font-size: 2.2rem;
}
.gestion-devise-container .css-1xn3edc {
    margin-bottom: 15px;
}
.gestion-devise-container .css-jpln7h-MuiTabs-scroller{
    background: #efefef;
}