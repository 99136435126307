@media screen and (min-width: 801px) {
  .type1{
    margin-top: 10vh;
  }
  .typeAccueil{
    margin-top: 10vh;

  }
  .typeAccueil .agence{
    padding-left:'10%';
    background-color: "#ebeef0";

  }
    .type1 .MuiTypography-root{
    font-size: 50px;
  }
  .type1 .MuiButtonBase-root{
    
    font-size:  16px;
  }
  .type2 .MuiButtonBase-root{
    font-size:  14px;
    margin: 5px;
  }
  .type2 .labelRoot {
    font-size: 16px;
  }
  .type2 .textFieldRoot {
    height: 40px;
  }
  .type2 .bartitle{
    width: 95%;
    margin-top:0.5%;
    margin-bottom: 0.5%;
    margin-left: 1.5%;

  }
  .type2 .span1{
    margin-left: 4px;

  }
  .type2 .contrevaleur{
    font-size: 18px;
    margin-top: 20px;
    padding: 2.5px;
  }
  .type2 .contrev{
    font-size: 18px;
    margin-top: 15px;
    margin-left: 40px;
    margin-right : 60px;
    margin-bottom: 20px;
    padding: 6px 25px;
  }
  .contrevv{
    font-size: 18px;
    margin-top: 15px;
    margin-left: 40px;
    margin-bottom: 20px;
    padding: 6px 25px;
  }
  .type3{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0% 2%;
  }
  .type2 .form1{
    background-color: rgb(243, 240, 238);
    padding: 1% 2%;
    width:  55%;
    margin-left: 7.5%;
  }
  .type2 .table1{

    width:30%;
    padding: 3%;
    margin-right: 7.5%;
  }
  .type2 .form2{
    background-color: #ffffff;
    padding: 4%;
    width:  80%;
    margin-left:10%;
  }

}@media screen and (max-width: 800px) {
  .type1{
    margin-top: 10vh;

  }
    .type1 .MuiTypography-root {
      font-size: 30px;
    }
    .type2 .h1 {
      font-size: 20px;
    }
    .type1 .MuiButtonBase-root{
        font-size:  12px;
      }
      .type2 .MuiButtonBase-root{
        font-size:  9px;
        margin: 3px;
      }
      .type2 .bartitle{
        width: 95%;
        margin-top:0.5%;
        margin-bottom: 0.5%;
        margin-left: 1.5%;

      }
      .type2 .span1{
        margin-left: 3px;

      }
      .type2 .labelRoot {
        font-size: 12px;
      }
      .type2 .textField {
        margin-top:2%;
        width:170px;
      }
      .type2 .textFieldRoot {
        height: 35px;
      }
      .type2 .contrevaleur{
            font-size: 20px;
            margin-top: 12px;
            padding: 1.5px;
        }
        .type2 .contrev{
            font-size: 20px;
            margin-top: 8px;
            margin-left: 40px;
            margin-right : 60px;
            margin-bottom: 100px;
            padding: 7px 35px;
        }
        .type3{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0% 2%;
          }
          .type2 .form1{
            background-color: rgb(255, 255, 255);
            flex: 1 0 100%;
          }
          .type2 .table1{

            flex: 1 0 100%;
            padding: 3%;
          }
          .MuiTable-root th{
            font-size:medium;
          }

  }@media screen and (max-width: 800px) {
  .type1{
    margin-top: 10vh;
  }
    .type1 .MuiTypography-root {
      font-size: 30px;
    }
    .type1 .MuiButtonBase-root{
        font-size:  12px;
      }
      .type2 .MuiButtonBase-root{
        font-size:  9px;
        margin: 3px;
      }
      .type2 .bartitle{
        width: 95%;
        margin-top:0.5%;
        margin-bottom: 0.5%;
        margin-left: 1.5%;

      }
      .type2 .span1{
        margin-left: 3px;

      }
      .type2 .contrevaleur{
            font-size: 20px;
            margin-top: 12px;
            padding: 1.5px;
        }
        .type2 .contrev{
            font-size: 20px;
            margin-top: 8px;
            margin-left: 40px;
            margin-right : 60px;
            margin-bottom: 100px;
            padding: 7px 35px;
        }
        .type3{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0% 2%;
          }
          .type2 .form1{
            background-color: rgb(243, 240, 238);
            flex: 1 0 100%;
            padding: 2%;
          }
          .type2 .table1{

            flex: 1 0 100%;
            padding: 3%;
          }
          .MuiTable-root th{
            font-size:x-small;
          }
          .MuiTable-root td{
            font-size:x-small;
          }
          button{
            font-size: small;
          }
        
  }

/* Styles for AppBar and Tabs */
.gestion-devise-container{
  margin-top: 7vh;
}
 .MuiAppBar-root {
  background-color: #ffffff;
}

.MuiTabs-root {
  background-color: #ffffff;
}

.MuiTab-root {
  font-size: 0.8rem;
  padding: 5px 35px 5px 20px !important;
}

.MuiTab-root:hover {
  color: #868dfb !important;
}

.MuiTab-root.Mui-selected {
  color: #6870fa !important;
}

/* Additional styling for content area */
.gestion-devise-content {
  margin: 1vh 0 0 0;
  height: 75vh;
}

/* Styling for specific elements within content area */
.MuiDataGrid-root {
  border: none;
}

.MuiDataGrid-cell {
  border-bottom: none;
}

.name-column--cell {
  color: #a7ff83; /* Assuming 'colors.greenAccent[300]' translates to this */
}

.MuiDataGrid-columnHeaders {
  background-color: #0052cc; /* Assuming 'colors.blueAccent[700]' translates to this */
  border-bottom: none;
}
.MuiDataGrid-virtualScroller {
  background-color: #005cbf; /* Assuming 'colors.primary[400]' translates to this */
}

.MuiDataGrid-footerContainer {
  border-top: none;
  background-color: #0052cc; /* Assuming 'colors.blueAccent[700]' translates to this */
}

.MuiCheckbox-root {
  color: #7cc57f !important; /* Assuming 'colors.greenAccent[200]' translates to this */
}

/* Additional styles for Typography */
.MuiTypography-root {
  font-size: 2.2rem;
}
 .css-1xn3edc {
  margin-bottom: 15px;
}
 .css-jpln7h-MuiTabs-scroller{
  background: #efefef;
}
.achat-clients-content {
  margin: 1vh 0 0 0;
  height: 75vh;
}
.container-table{
  margin: 0 20px 0 20px
}
.buttons{
  margin: 0.5rem 0.5rem -0.2rem 0.5rem;
  font-size: 0.7rem;
  height: 2.5rem;
  padding-x: 1.2rem;
  padding-y: 0.2rem;
}
.header-operation{
  display:flex;
  justify-content:space-between;
  padding:0.1vh 15% 0.1vh 15%;
  background-color:rgb(243, 240, 238);
  margin:-2vh 9% 0.5vh 9%;
}